<template>
  <b-container fluid>
    <b-row v-if="message">
      <b-col>
        <b-card :header="$t('Subject')">
          {{ contents.subject }}
        </b-card>

        <b-card header="HTML">
          <iframe class="html-email" :srcdoc="contents.html"></iframe>
        </b-card>

        <b-card header="Text">
          <pre>{{ contents.text }}</pre>
        </b-card>
      </b-col>

      <b-col v-if="snippet">
        <b-card header="Edit">
          <b-spinner label="Spinning" v-if="saving"></b-spinner>

          <div style="height: 70vh;" v-show="!saving">
            <code-editor 
              v-model="snippet.Content"
            />
          </div>
          
        </b-card>
        <b-button class="mt-3" @click="save()">Save</b-button>
      </b-col>
    </b-row>

    <b-spinner label="Spinning" v-else></b-spinner>
  </b-container>
</template>

<script>
  
  //
  export default {
    components: {
      'code-editor': () => import('@/components/CodeEditor.vue'),
    },
    data () {
      return {
        message: null,
        snippet: null,
        saving: false,
      }
    },
    props: {
      beneficiaryCode: String,
      accountingPeriodId: String,
    },
    computed: {
      contents() {
        if (!this.message) {
          return null
        }
        return JSON.parse(this.message?.Contents)
      },
      srcData() {
        if (!this.message) {
          return null
        }
        return JSON.parse(this.message?.SourceData)
      }
    },
    methods: {
      async save () {
        try {
          this.saving = true
          await this.$http.put(
            'ta-snippets/' + this.snippet.SnippetID,
            {
              Content: this.snippet.Content //.replaceAll('\r\n', '\n')
            }
          )
        } catch (error) {
          console.log(error)
          this.$notify({
              type: 'error',
              text: `An error occurred while saving`,
              duration: -1,
            });
        } finally {
          this.saving = false
        }
      },
    },
    async mounted () {

      let response = await this.$http.get(
        `/beneficiary/${this.beneficiaryCode}/notification-email/${this.accountingPeriodId}`
      )

      this.message = response.data

      response = await this.$http.get(
        `/ta-snippets/${this.srcData.SnippetID}`
      )
      this.snippet = response.data
    }
  }
</script>

<style lang="scss">
  .html-email {
    all: reset; 
    width: 100%; 
    height: 80vh; 
    border: none;

    table {
      table-layout: fixed;
    }
  }
</style>